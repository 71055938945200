import { render, staticRenderFns } from "./search-suggestion.vue?vue&type=template&id=06f6a0c3&scoped=true&"
import script from "./search-suggestion.vue?vue&type=script&lang=js&"
export * from "./search-suggestion.vue?vue&type=script&lang=js&"
import style0 from "./search-suggestion.vue?vue&type=style&index=0&id=06f6a0c3&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06f6a0c3",
  null
  
)

export default component.exports